import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import "../../styles/homeComponentStyles/learnerFeedback.css"

const LearnerFeedback = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 5000,
    accessibility: true,
    arrows: true,
    cssEase: "linear",
  }
  return (
    <section className="lfeedback-section">
      {/* <!-- Container --> */}
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="lfeedback_img" id="scene3">
              <StaticImage
                src="../../images/feedback.png"
                alt="feedback"
                className="layer"
                data-depth="0.28"
                placeholder="blurred"
                layout="constrained"
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12" id="lfeedback_cur">
            <Slider {...settings}>
              <div className="lfeedback_item">
                {/* <!-- Start: Heading --> */}
                <div className="base-header">
                  <h3> Our Learners Feedback </h3>
                </div>
                {/* <!-- Best Book Image 1 --> */}
                <div className="lfeedback_text">
                  <p>
                    {" "}
                    It's Had been a fear most experience me that I feel a great
                    assumption that never thoughts that will happens to But
                    great provocatives things appropities received without
                    realmost qualifier that happen that never thoughts that will
                    happens to a fear most experience.{" "}
                  </p>
                  <h4> David Benjamin </h4>
                  <h5>Washington, United States</h5>
                </div>
              </div>
              <div className="lfeedback_item">
                {/* <!-- Start: Heading --> */}
                <div className="base-header">
                  <h3> Our Learners Feedback </h3>
                </div>
                {/* <!-- Best Book Image 1 --> */}
                <div className="lfeedback_text">
                  <p>
                    {" "}
                    It's Had been a fear most experience me that I feel a great
                    assumption that never thoughts that will happens to But
                    great provocatives things appropities received without
                    realmost qualifier that happen that never thoughts that will
                    happens to a fear most experience.{" "}
                  </p>
                  <h4> David Benjamin </h4>
                  <h5>Washington, United States</h5>
                </div>
              </div>
            </Slider>
          </div>
          {/* <!--/ col-md-12  --> */}
        </div>
        {/* <!--/ row - --> */}
      </div>
      {/* <!--/ Container - --> */}
    </section>
  )
}

export default LearnerFeedback
