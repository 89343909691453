import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar, faHeart } from "@fortawesome/free-solid-svg-icons"
import "../../styles/homeComponentStyles/aboutUs.css"

const AboutUs = () => {
  return (
    <section className="about-section">
      {/* <!-- Container --> */}
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="abt_rating">
              <h4> 4.9+</h4>
              <i>
                <FontAwesomeIcon icon={faStar} />{" "}
              </i>
              <i>
                <FontAwesomeIcon icon={faStar} />{" "}
              </i>
              <i>
                <FontAwesomeIcon icon={faStar} />{" "}
              </i>
              <i>
                <FontAwesomeIcon icon={faStar} />{" "}
              </i>
              <i>
                <FontAwesomeIcon icon={faStar} />{" "}
              </i>
              <span> User Rating</span>
            </div>
            {/* <!-- /.abt_rating--> */}
            <div className="about_img" id="scene2">
              <StaticImage
                src="../../images/about.png"
                alt="about"
                className="layer"
                data-depth="0.28"
                placeholder="blurred"
                layout="constrained"
              />
            </div>
            {/* <!-- /.about_img--> */}
            <div className="abt_course">
              <h4>47K+</h4>
              <i>
                {" "}
                <FontAwesomeIcon icon={faHeart} />
              </i>
              <i>
                {" "}
                <FontAwesomeIcon icon={faHeart} />
              </i>
              <i>
                {" "}
                <FontAwesomeIcon icon={faHeart} />
              </i>
              <i>
                {" "}
                <FontAwesomeIcon icon={faHeart} />
              </i>
              <i>
                {" "}
                <FontAwesomeIcon icon={faHeart} />
              </i>
              <span> Active Courses</span>
            </div>
            {/* <!-- /.abt_course--> */}
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="about_text">
              <h3> We Make Your Learning Through Awesome </h3>
              <p>
                {" "}
                Become the dolor sit amet, consetetur sadipscing elitr, sed diam
                nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
                erat, sed diam voluptua. At vero eos et accusam et justo duo
                dolores et ea rebum. Stet.
              </p>
              <Link to="contact.html" className="more-link">
                {" "}
                Learn More{" "}
              </Link>
            </div>
          </div>
          {/* <!--/ col-md-12  --> */}
        </div>
        {/* <!--/ row - --> */}
      </div>
      {/* <!--/ Container - --> */}
    </section>
  )
}

export default AboutUs
