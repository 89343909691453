import React from "react"
import "../../styles/homeComponentStyles/workFlow.css"

const WorkFlow = () => {
  return (
    <section className="workflow-section pt-120">
      {/* <!-- Container --> */}
      <div className="container">
        {/* <!-- Start: Heading --> */}
        <div className="base-header text-center">
          <h3> Our Work Flow</h3>
        </div>
        {/* <!-- End: Heading --> */}
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            {/* <!-- category 1 --> */}
            <div className="workflow_item">
              <i className="pe-7s-search"></i>
              <h4> Find Your Course </h4>
              <p>
                Lorem ipsum dolor sit amet can be sed diam nonumy eirmod keeps
                an the satriction of whole life that enter.
              </p>
            </div>
          </div>
          {/* <!--/ col-lg-4 col-md-6 col-sm-12  --> */}
          <div className="col-lg-4 col-md-6 col-sm-12">
            {/* <!-- category 1 --> */}
            <div className="workflow_item">
              <i className="pe-7s-date"></i>
              <h4>Book Your Seat </h4>
              <p>
                Lorem ipsum dolor sit amet can be sed diam nonumy eirmod keeps
                an the satriction of whole life that enter.
              </p>
            </div>
          </div>
          {/* <!--/ col-lg-4 col-md-6 col-sm-12  --> */}
          <div className="col-lg-4 col-md-6 col-sm-12">
            {/* <!-- category 1 --> */}
            <div className="workflow_item">
              <i className="pe-7s-medal"></i>
              <h4> Instant Certified </h4>
              <p>
                Lorem ipsum dolor sit amet can be sed diam nonumy eirmod keeps
                an the satriction of whole life that enter.
              </p>
            </div>
          </div>
          {/* <!--/ col-lg-4 col-md-6 col-sm-12  --> */}
        </div>
        {/* <!--/ row - --> */}
      </div>
      {/* <!--/ Container - --> */}
    </section>
  )
}

export default WorkFlow
